// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require jquery_ujs
//= require tinymce
import "bootstrap";
import "../stylesheets/application"; // This file will contain your custom CSS
require("admin-lte");
import "@fortawesome/fontawesome-free/js/all";
// 画像読み込み
const images = require.context("../images/", true);
const imagePath = (name) => images(name, true);

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
});

var Rails = require("@rails/ujs");
window.Rails = Rails;
Rails.start();
